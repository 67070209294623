import { doc, getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { Location } from "../types";

export const getDocRef = (docRef: string) => {
  const db = getFirestore();
  return doc(db, `locations/${docRef}`);
};

export const getFileRef = (
  selectedLocation: Location,
  url: string,
  For: "main_menu" | "banners" | "reward",
) => {
  const locationName = selectedLocation.name.replace(/[^a-zA-Z0-9]/g, "_");
  const urlParts = url.split("/");
  const fileName = decodeURIComponent(
    urlParts[urlParts.length - 1].split("?")[0],
  )
    .split("/")
    .at(-1);
  const storage = getStorage();
  switch (For) {
    case "main_menu":
      return ref(storage, `location_pictures/${locationName}/${fileName}`);
    case "banners":
      return ref(
        storage,
        `location_pictures/${locationName}/banners/${fileName}`,
      );
    case "reward":
      return ref(
        storage,
        `location_pictures/${locationName}/rewards/${fileName}`,
      );
  }
};
