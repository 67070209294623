import { useForm } from "react-hook-form";
import { Location } from "../../../../types";
import SharedInputs from "../global/Inputs/SharedInputs";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { onCreate } from "./actions";
import CustomInput from "../global/Inputs/CustomInput";
import { setKey } from "react-geocode";
import { createFormDefaultValues } from "../../../../data";
import { useActionsContext } from "../../../../contexts/ActionsManagerProvider";

export default function CreateLocationForm() {
  const methods = useForm<Location>({
    defaultValues: createFormDefaultValues,
  });
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = methods;
  const queryClient = useQueryClient();
  const { selectedOptions, setSelectedOptions, setIsSubmitted } =
    useOptionsContext();
  const context = useActionsContext();
  const { mode } = context.state;

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "");

  const handleOnSubmit = async (data: Location) => {
    try {
      await onCreate(data, selectedOptions, queryClient);
      setSuccessMessage("Location created successfully!");
      setErrorMessage(null);
      reset(createFormDefaultValues);
      setSelectedOptions({
        mainTag: "",
        additionalTag: "",
        files: {
          main_menu: null,
          banners: [],
          rewards: [],
        },
      });
      setIsSubmitted(true);
    } catch (error) {
      setErrorMessage("Failed to create location with error: " + error);
      setSuccessMessage(null);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage(null);
      setErrorMessage(null);
    }, 5000);

    return () => clearTimeout(timer);
  }, [successMessage, errorMessage]);

  return (
    <>
      <h2 className="text-default-white text-xl pt-3">CREATE LOCATION</h2>
      <div className="mt-7 h-auto pb-7">
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <CustomInput
            register={register}
            fieldName="pictureHash"
            type="text"
            placeholder="e.g. L7EyJRxUyZItQ8NGW"
            options={{
              required: mode === "CREATE" ? "This field is required!" : false,
            }}
            className="mt-1"
            error={errors.pictureHash?.message}
            label="Picture Hash Link"
          />
          <SharedInputs formMethods={methods} />
          <input
            type="submit"
            className="w-full mt-4 py-2 bg-admin-purple cursor-pointer"
            value="Create Location"
          />
        </form>

        {successMessage && (
          <p className="text-green-500 mt-2">{successMessage}</p>
        )}
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      </div>
    </>
  );
}
