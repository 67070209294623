import { useForm } from "react-hook-form";
import { Location } from "../../../../types";
import SharedInputs from "../global/Inputs/SharedInputs";
import LocationSelector from "../Dropdown/LocationSelector";
import { updateFormDefaultValues } from "../../../../data";
import { useLocations } from "../../../../contexts/LocationsProvider";
import { useEffect, useState } from "react";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { getAddress, onUpdate } from "./actions";

export default function UpdateLocationForm() {
  const methods = useForm<Location>({
    defaultValues: updateFormDefaultValues,
  });
  const {
    formState: { isSubmitted },
    reset,
    setValue,
    trigger,
    handleSubmit,
    watch,
  } = methods;
  const newData = watch();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { selectedLocation } = useLocations();
  const { selectedOptions, setSelectedOptions, removedFiles, setRemovedFiles } =
    useOptionsContext();

  useEffect(() => {
    if (selectedLocation) {
      updateFormDefaultValues.name = selectedLocation.name;
      updateFormDefaultValues.description = selectedLocation.description;
      updateFormDefaultValues.pointsRate = selectedLocation.pointsRate;
      updateFormDefaultValues.multiLocations =
        selectedLocation.multiLocations.map((loc) => ({
          city: loc.city,
          geoLocation: "",
          locationName: loc.locationName,
          phone: loc.phone,
          pictures: loc.pictures,
        }));
      updateFormDefaultValues.picture = selectedLocation.picture;
      updateFormDefaultValues.rewards = selectedLocation.rewards;
      updateFormDefaultValues.tags = selectedLocation.tags;

      setSelectedOptions({
        mainTag: selectedLocation.tags[0],
        additionalTag: selectedLocation.tags[1],
        files: {
          main_menu: null,
          banners: [],
          rewards: [],
        },
      });

      selectedLocation.multiLocations.forEach(async (_, index) => {
        const address: string = await getAddress(index, selectedLocation);
        setValue(`multiLocations.${index}.geoLocation`, address);
        await trigger(`multiLocations.${index}.geoLocation`);
      });

      reset(updateFormDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, isSubmitted]);

  return (
    <>
      <h2 className="text-default-white text-xl pt-3">UPDATE LOCATION</h2>
      <div className="mt-7 h-auto">
        <LocationSelector />
        <form
          onSubmit={handleSubmit(() =>
            onUpdate(
              newData,
              selectedLocation,
              selectedOptions,
              removedFiles,
              setRemovedFiles,
              setSuccessMessage,
              setErrorMessage,
            ),
          )}
        >
          <SharedInputs formMethods={methods} />
          <input
            type="submit"
            className="w-full mt-4 py-2 mb-10 bg-admin-purple cursor-pointer"
            value="Update Location"
          />
        </form>
        {successMessage && (
          <p className="text-green-500 mt-2">{successMessage}</p>
        )}
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      </div>
    </>
  );
}
