import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import {
  CreateLocationOptions,
  Location,
  RemovedFile,
} from "../../../../../types";
import { getFileRef } from "../../../../../firebase/actions";

type FileChange = {
  e: SyntheticEvent;
  setPreviewUrls: Dispatch<SetStateAction<string[]>>;
  For: "main_menu" | "banners" | "reward";
  setSelectedOptions: Dispatch<SetStateAction<CreateLocationOptions>>;
};

type FileDeleletion = Omit<FileChange, "e"> & {
  previewUrls: string[];
  idx: number;
  index?: number;
  mode?: "UPDATE" | "CREATE" | "DELETE";
  selectedLocation?: Location | null;
  setRemovedFiles: Dispatch<SetStateAction<RemovedFile[]>>;
};
export const handleFileChange = async ({
  e,
  setPreviewUrls,
  For,
  setSelectedOptions,
}: FileChange) => {
  const target = e.target as HTMLInputElement;
  if (target.files) {
    const filesArray = Array.from(target.files);

    const urls = await Promise.all(
      filesArray.map((file) => URL.createObjectURL(file)),
    );
    setPreviewUrls((prevUrls) => [...prevUrls, ...urls]);

    setSelectedOptions((prevOptions) => {
      const auxFiles = { ...prevOptions.files };

      switch (For) {
        case "main_menu":
          auxFiles.main_menu = filesArray[0];
          break;

        case "banners":
          const bannerIndex = auxFiles.banners.length;
          if (auxFiles.banners[bannerIndex] === undefined)
            auxFiles.banners[bannerIndex] = { images: [] };
          auxFiles.banners[bannerIndex].images = filesArray;
          break;

        case "reward":
          auxFiles.rewards = [...(auxFiles.rewards || []), filesArray[0]];
          break;

        default:
          break;
      }

      return { ...prevOptions, files: auxFiles };
    });
  }
};

export const handleDeleteFile = ({
  setPreviewUrls,
  For,
  setSelectedOptions,
  previewUrls,
  idx,
  mode,
  selectedLocation,
  setRemovedFiles,
  index,
}: FileDeleletion) => {
  const auxPreviewUrls = [...previewUrls];
  auxPreviewUrls.splice(idx, 1);
  setPreviewUrls(auxPreviewUrls);

  setSelectedOptions((prevOptions) => {
    const auxFiles = { ...prevOptions.files };

    switch (For) {
      case "main_menu":
        if (mode === "CREATE") auxFiles.main_menu = null;
        else if (mode === "UPDATE") {
          if (selectedLocation) {
            const url = previewUrls[0];
            const fileRef = getFileRef(selectedLocation, url, For);
            const urlArray = fileRef.fullPath.split("/");
            const fileName = urlArray[urlArray.length - 1];
            setRemovedFiles((prev) => [
              ...prev,
              {
                removedFile: {
                  deleteFrom: fileRef,
                  name: fileName,
                  from: For,
                },
              },
            ]);
          }
        }
        break;

      case "banners":
        if (mode === "CREATE" && index !== undefined) {
          const images = auxFiles.banners[index].images;
          images.splice(idx, 1);
          auxFiles.banners[index].images = images;
        } else if (mode === "UPDATE" && index !== undefined) {
          console.log("here 1");

          if (selectedLocation) {
            const images = auxFiles.banners[index]?.images || [];

            if (images.length > 0) {
              images.splice(idx, 1);
              auxFiles.banners[index].images = images;
            }

            const previewImagesLength =
              selectedLocation.multiLocations[index].pictures.length;
            if (idx >= previewImagesLength) {
              const fileIndex = idx - previewImagesLength;
              images.splice(fileIndex, 1);
              auxFiles.banners[index].images = images;
            } else {
              const url = previewUrls[idx];
              const fileRef = getFileRef(selectedLocation, url, For);
              const urlArray = fileRef.fullPath.split("/");
              const fileName = urlArray[urlArray.length - 1];

              setRemovedFiles((prev) => [
                ...prev,
                {
                  removedFile: {
                    deleteFrom: fileRef,
                    name: fileName,
                    index: index,
                    from: For,
                  },
                },
              ]);
            }
          }
        }

        break;

      case "reward":
        if (mode === "CREATE") auxFiles.rewards.splice(idx, 1);
        else if (mode === "UPDATE" && index) {
          if (selectedLocation) {
            const url = previewUrls[0];
            const fileRef = getFileRef(selectedLocation, url, For);
            const urlArray = fileRef.fullPath.split("/");
            const fileName = urlArray[urlArray.length - 1];
            setRemovedFiles((prev) => [
              ...prev,
              {
                removedFile: {
                  deleteFrom: fileRef,
                  name: fileName,
                  index: index,
                  from: For,
                },
              },
            ]);
          }
        }

        break;

      default:
        break;
    }

    return { ...prevOptions, files: auxFiles };
  });
};
