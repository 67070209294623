import React from "react";
import { Path, useFieldArray, UseFormReturn } from "react-hook-form";
import { Location } from "../../../../../types";
import FileInput from "./FileInput";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";

interface Props {
  formMethods: UseFormReturn<Location>;
}

type FormPath = Path<Location>;

export default function RewardInputs({ formMethods }: Props) {
  const { register, control, formState, setError } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "rewards",
  });
  const context = useActionsContext();
  const { mode } = context.state;
  const { errors } = formState;

  return (
    <div className="mt-8">
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`bg-admin-dark-3 rounded-md flex items-center justify-center ${
            index > 0 ? "mt-2" : "mt-4 pb-7"
          }`}
        >
          <div className="w-11/12">
            <h3 className="pt-2 text-default-white">Reward {index + 1}</h3>
            <label className="mt-4 block text-admin-purple">Name</label>
            <input
              {...register(`rewards.${index}.rewardTitle` as FormPath, {
                required: mode === "CREATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder="Anything you fancy offering."
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500"
            />
            {errors.rewards?.[index]?.rewardTitle?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.rewards?.[index]?.rewardTitle?.message)}
              </span>
            )}
            <label className="mt-4 block text-admin-purple">Price</label>
            <input
              {...register(`rewards.${index}.rewardCost` as FormPath, {
                required: mode === "CREATE" ? "This field is required!" : false,
                valueAsNumber: true,
              })}
              type="number"
              inputMode="numeric"
              placeholder="Price of the reward"
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                  e.preventDefault();
                }
              }}
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            />
            {errors.rewards?.[index]?.rewardCost?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.rewards?.[index]?.rewardCost?.message)}
              </span>
            )}
            <label className="mt-4 block text-admin-purple">Description</label>
            <input
              {...register(`rewards.${index}.rewardDescription` as FormPath, {
                required: mode === "CREATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder="Short description"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500"
            />
            {errors.rewards?.[index]?.rewardDescription?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.rewards?.[index]?.rewardDescription?.message)}
              </span>
            )}
            <FileInput
              register={register}
              setError={setError}
              fieldName={`rewards.${index}.rewardPicture` as FormPath}
              options={{
                required: mode === "CREATE" ? "This field is required!" : false,
              }}
              label={`${
                mode === "CREATE"
                  ? "Upload a reward image"
                  : "Change the reward image"
              }`}
              For="reward"
              formState={formState}
              index={index}
            />

            {index > 0 && (
              <button
                onClick={() => remove(index)}
                className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      ))}
      <button
        onClick={(e) => {
          e.preventDefault();
          append({
            rewardTitle: "",
            rewardCost: null,
            rewardDescription: "",
            rewardPicture: "",
          });
        }}
        className="flex items-center justify-center border-[1px] border-admin-purple rounded-md p-2 mt-4 "
      >
        <img src="/assets/add-icon.png" alt="Add Icon" className="w-5 h-auto" />
        <p className="text-default-white">Add more rewards</p>
      </button>
    </div>
  );
}
