import React, { SyntheticEvent, useEffect, useState } from "react";
import { Location } from "../../../../../types";
import { useOptionsContext } from "../../../../../contexts/OptionsProvider";
import {
  FormState,
  Path,
  UseFormRegister,
  UseFormSetError,
  ValidationRule,
} from "react-hook-form";
import { handleDeleteFile, handleFileChange } from "./actions";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";
import { useLocations } from "../../../../../contexts/LocationsProvider";
import { ErrorMessage } from "@hookform/error-message";

type FieldName = Path<Location>;

interface Props {
  register: UseFormRegister<Location>;
  setError: UseFormSetError<Location>;
  formState: FormState<Location>;
  fieldName: FieldName;
  label: string;
  For: "main_menu" | "banners" | "reward";
  options?: {
    required?: string | ValidationRule<boolean> | undefined;
    onChange?: (e: SyntheticEvent) => void;
  };
  index?: number;
}

export default function FileInput({
  register,
  setError,
  fieldName,
  options,
  label,
  For,
  formState: { errors, isSubmitted },
  index,
}: Props) {
  const { setSelectedOptions, setRemovedFiles } = useOptionsContext();
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const { state } = useActionsContext();
  const { mode } = state;
  const { selectedLocation } = useLocations();

  useEffect(() => {
    if (mode === "UPDATE" && selectedLocation) {
      switch (For) {
        case "main_menu":
          setPreviewUrls([selectedLocation.picture]);
          break;
        case "reward":
          if (
            index !== undefined &&
            selectedLocation.rewards[index] !== undefined
          )
            setPreviewUrls([selectedLocation.rewards[index].rewardPicture]);
          break;
        case "banners":
          if (
            index !== undefined &&
            selectedLocation.multiLocations[index] !== undefined
          )
            setPreviewUrls(selectedLocation.multiLocations[index].pictures);

          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  useEffect(() => {
    if (mode === "CREATE") {
      setError(fieldName, {
        type: "manual",
        message: "You must upload at least a file!",
      });
    }
    if (
      mode === "UPDATE" &&
      isSubmitted &&
      (previewUrls.length === 0 || previewUrls[0] === "")
    ) {
      setError(fieldName, {
        type: "manual",
        message: "You must upload at least one image!",
      });
    }
  }, [setError, isSubmitted, errors, fieldName, mode, previewUrls]);

  return (
    <div className="mt-4 h-auto">
      <label
        htmlFor={fieldName}
        className="p-2 rounded-md bg-admin-purple text-default-white cursor-pointer block w-fit"
      >
        {label}
      </label>
      <input
        {...register(fieldName, {
          ...options,
          onChange: (e: SyntheticEvent) =>
            handleFileChange({ e, setPreviewUrls, For, setSelectedOptions }),
        })}
        id={fieldName}
        type="file"
        multiple={For === "banners"}
        className="hidden"
      />
      {(previewUrls.length === 0 ||
        (mode === "UPDATE" && previewUrls[0] === "")) && (
        <ErrorMessage
          errors={errors}
          name={fieldName}
          render={({ message }) => <p className="text-red-500">{message}</p>}
        />
      )}

      <div className="grid grid-cols-2 gap-2 -mt-3">
        {previewUrls[0] !== "" &&
          previewUrls.map((url, idx) => (
            <div key={idx}>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteFile({
                    previewUrls,
                    idx,
                    setPreviewUrls,
                    setSelectedOptions,
                    For,
                    mode,
                    selectedLocation,
                    setRemovedFiles,
                    index,
                  });
                }}
              >
                <img
                  src="/assets/delete-icon.png"
                  alt="Delete Icon"
                  className="w-6 h-auto absolute mt-1 ml-1"
                />
              </button>
              <img
                src={url}
                alt="Selected File"
                className="w-30 h-auto object-cover"
              />
            </div>
          ))}
      </div>
    </div>
  );
}
