import { Helmet } from "react-helmet-async";
import { LoginForm } from "../../components";

export default function Admin() {
  return (
    <>
      <Helmet>
        <title>Resturio Admin</title>
      </Helmet>
      <div className="w-screen h-screen bg-dark flex items-center justify-center">
        <div className="w-4/5 h-auto flex flex-col items-center md:w-1/2 lg:w-1/4">
          <h1 className="text-themed-orange text-3xl">Admin Login</h1>
          <LoginForm />
        </div>
      </div>
    </>
  );
}
