import React from "react";
import { Path, useFieldArray, UseFormReturn } from "react-hook-form";
import { Location } from "../../../../../types";
import FileInput from "./FileInput";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";

interface Props {
  formMethods: UseFormReturn<Location>;
}

type FormPath = Path<Location>;
export default function MultipleLocationInputs({ formMethods }: Props) {
  const { register, control, formState, setError } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "multiLocations",
  });
  const context = useActionsContext();
  const { mode } = context.state;
  const { errors } = formState;
  return (
    <div className="mt-8">
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`bg-admin-dark-3 rounded-md flex items-center justify-center ${
            index > 0 ? "mt-2" : "mt-4 pb-7"
          }`}
        >
          <div className="w-11/12">
            <h3 className="pt-2 text-default-white">Location {index + 1}</h3>
            <label className="mt-4 block text-admin-purple">City</label>
            <input
              {...register(`multiLocations.${index}.city` as FormPath, {
                required: mode === "CREATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder="Human settlement of a substantial size"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            />
            {errors.multiLocations?.[index]?.city?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.multiLocations?.[index]?.city?.message)}
              </span>
            )}
            <label className="mt-4 block text-admin-purple">Address</label>
            <input
              {...register(`multiLocations.${index}.geoLocation` as FormPath, {
                required: mode === "CREATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder="Google address"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            />
            {errors.multiLocations?.[index]?.geoLocation?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.multiLocations?.[index]?.geoLocation?.message)}
              </span>
            )}
            <label className="mt-4 block text-admin-purple">
              Specific location identifier
            </label>
            <input
              {...register(`multiLocations.${index}.locationName` as FormPath, {
                required: mode === "CREATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder='e.g: "Victoriei"'
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500"
            />
            {errors.multiLocations?.[index]?.locationName?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.multiLocations?.[index]?.locationName?.message)}
              </span>
            )}
            <label className="mt-4 block text-admin-purple">Phone number</label>
            <input
              {...register(`multiLocations.${index}.phone` as FormPath, {
                required: mode === "CREATE" ? "This field is required!" : false,
              })}
              type="text"
              inputMode="numeric"
              placeholder="0712345678"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500"
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                  e.preventDefault();
                }
              }}
            />
            {errors.multiLocations?.[index]?.phone?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.multiLocations?.[index]?.phone?.message)}
              </span>
            )}
            {mode === "CREATE" && (
              <>
                <label className="mt-4 block text-admin-purple">
                  Picture Hash Link
                </label>
                <input
                  {...register(
                    `multiLocations.${index}.pictureHash` as FormPath,
                    { required: "This field is required!" },
                  )}
                  type="text"
                  placeholder="e.g. L7EyJRxUyZItQ8NGW"
                  className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500"
                />
              </>
            )}
            {errors.multiLocations?.[index]?.pictureHash?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.multiLocations?.[index]?.pictureHash?.message)}
              </span>
            )}
            <FileInput
              register={register}
              setError={setError}
              fieldName={`multiLocations.${index}.pictures` as FormPath}
              options={{
                required: mode === "CREATE" ? "This field is required!" : false,
              }}
              label={`${
                mode === "CREATE"
                  ? "Upload banner images"
                  : "Change banner images"
              }`}
              For="banners"
              formState={formState}
              index={index}
            />

            {index > 0 && (
              <button
                onClick={() => remove(index)}
                className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      ))}
      <button
        onClick={(e) => {
          e.preventDefault();
          append({
            city: "",
            geoLocation: "",
            locationName: "",
            phone: "",
            pictureHash: "",
            pictures: [],
          });
        }}
        className="flex items-center justify-center border-[1px] border-admin-purple rounded-md p-2 mt-4 "
      >
        <img src="/assets/add-icon.png" alt="Add Icon" className="w-5 h-auto" />
        <p className="text-default-white">Add more locations</p>
      </button>
    </div>
  );
}
