import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useGetLocations } from "../components/Admin/Dashboard/actions";
import { Location } from "../types";

interface LocationsContextInterface {
  locations: Location[];
  isLoading: boolean;
  error: any;
  selectedLocation: Location | null;
  setSelectedLocation: (location: Location | null) => void;
}

type Props = {
  children: ReactNode;
};

const LocationsContext = createContext<LocationsContextInterface | null>(null);

export const useLocations = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error("useLocations must be used within a LocationsProvider");
  }
  return context;
};

export default function LocationsProvider({ children }: Props) {
  const { data: locations, isLoading, error } = useGetLocations();
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );

  useEffect(() => {
    if (locations && locations.length > 0 && !selectedLocation) {
      const sortedLocations = [...locations].sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setSelectedLocation(sortedLocations[0]);
    }
  }, [locations, selectedLocation]);

  if (!locations) return <div>No locations found</div>;

  return (
    <LocationsContext.Provider
      value={{
        locations: locations ?? [],
        isLoading,
        error,
        selectedLocation,
        setSelectedLocation,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
}
