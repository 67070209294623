import React, { useState } from "react";
import LocationSelector from "../Dropdown/LocationSelector";
import { useLocations } from "../../../../contexts/LocationsProvider";
import { deleteLocation } from "./actions";
import { useQueryClient } from "@tanstack/react-query";

export default function DeleteLocation() {
  const { selectedLocation } = useLocations();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const queryClient = useQueryClient();
  return (
    <>
      <h2 className="text-default-white text-xl pt-3">DELETE LOCATION</h2>
      <div className="mt-7 h-auto">
        <LocationSelector />
        <button
          onClick={() =>
            deleteLocation(
              selectedLocation,
              setSuccessMessage,
              setErrorMessage,
              queryClient,
            )
          }
          className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
        >
          Delete
        </button>
        {successMessage && (
          <p className="text-green-500 mt-2">{successMessage}</p>
        )}
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      </div>
    </>
  );
}
