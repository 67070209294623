import { useActionsContext } from "../../../contexts/ActionsManagerProvider";
import CreateLocation from "./LocationForms/CreateLocationForm";
import UpdateLocation from "./LocationForms/UpdateLocationForm";
import DeleteLocation from "./LocationForms/DeleteLocation";

const componentMap = {
  CREATE: <CreateLocation />,
  UPDATE: <UpdateLocation />,
  DELETE: <DeleteLocation />,
};

export default function LocationManager() {
  const context = useActionsContext();

  const { state } = context;
  return (
    <div className="w-auto h-auto bg-admin-dark rounded-md shadow-[1px_3px_8px_-5px_#6c5ce7] px-4">
      {componentMap[state.componentId]}
    </div>
  );
}
