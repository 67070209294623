import { ExtendedUser } from "../types";

type AuthState = {
  currentUser: ExtendedUser | null;
};

export type Actions =
  | { type: "LOGIN"; payload: ExtendedUser }
  | { type: "LOGOUT" };

const AuthReducer = (state: AuthState, action: Actions) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        currentUser: null,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
