import { Credentials, ExtendedUser } from "../../types";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { Dispatch, SetStateAction } from "react";
import { Actions } from "../../reducers/AuthReducer";
import { NavigateFunction } from "react-router-dom";
import { doc, getDoc, getFirestore } from "firebase/firestore";

export const onSubmit = async (
  data: Credentials,
  dispatch: Dispatch<Actions>,
  setAuthError: Dispatch<SetStateAction<string | null>>,
  navigate: NavigateFunction,
) => {
  const { email, password } = data;

  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredentials.user;
    const db = getFirestore();
    const userDocRef = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const userType = userData.type || "user";

      const extendedUser: ExtendedUser = { ...user, type: userType };

      dispatch({ type: "LOGIN", payload: extendedUser });
      setAuthError(null);
      navigate("/admin/dashboard");
    }
  } catch (error) {
    console.error("Error signing in user", error);
    setAuthError("Wrong credentials! How did you find this page anyway?");
  }
};
