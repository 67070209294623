import { useQuery, useQueryClient } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase/firebaseConfig";
import { Location } from "../../../types";

export const useHandleSignOut = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return async () => {
    try {
      await signOut(auth);
      await queryClient.invalidateQueries({ queryKey: ["users"] });
      localStorage.removeItem("user");
      navigate("/admin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
};

const db = getFirestore();

export const fetchUserName = async () => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) return null;

    const userDocRef = doc(db, "users", currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) return "Guest";

    return userDocSnap.data().display_name;
  } catch (error) {
    console.error("Error fetching user name:", error);
    throw error;
  }
};

export const useGetUsername = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: fetchUserName,
    staleTime: 0,
    refetchOnWindowFocus: true,
  });
};

export const fetchLocations = async () => {
  try {
    const locationsColRef = collection(db, "locations");
    const locationsColSnap = await getDocs(locationsColRef);
    return locationsColSnap.docs.map((doc) => {
      const data = doc.data();
      return {
        docID: doc.id,
        name: data.name,
        description: data.description,
        cities: data.cities,
        dateAdded: data.dateAdded,
        docRef: data.docRef,
        locationOTD: data.locationOTD,
        multiLocations: data.multiLocations,
        scannedQRs: data.scannedQRs,
        picture: data.picture,
        pictureHash: data.pictureHash,
        pointsRate: data.pointsRate,
        rewards: data.rewards,
        tags: data.tags,
      } as Location;
    });
  } catch (error) {
    console.error("Error fetching locations: ", error);

    throw error;
  }
};

export const useGetLocations = () => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
    staleTime: 0,
    refetchOnWindowFocus: true,
  });
};
