import { Helmet } from "react-helmet-async";
import {
  ActionDropdown,
  AdminNavbar,
  LocationManager,
  MobileLayout,
} from "../../components";
import { ActionsManagerProvider, LocationsProvider } from "./../../contexts";
import { OptionsProvider } from "../../contexts/OptionsProvider";

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Resturio Admin Dashboard</title>
      </Helmet>
      <LocationsProvider>
        <ActionsManagerProvider>
          <div className="w-screen h-[200vh] bg-dark absolute top-0">
            <AdminNavbar />
            <ActionDropdown />
            <MobileLayout className="mt-52">
              <OptionsProvider>
                <LocationManager />
              </OptionsProvider>
            </MobileLayout>
          </div>
        </ActionsManagerProvider>
      </LocationsProvider>
    </>
  );
}
