import {
  createContext,
  Dispatch,
  ReactNode,
  useEffect,
  useReducer,
} from "react";
import { AuthReducer } from "../reducers";
import { Actions } from "../reducers/AuthReducer";
import { ExtendedUser } from "../types";

type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextType = {
  currentUser: ExtendedUser | null;
  dispatch: Dispatch<Actions>;
};

const INITIAL_STATE: AuthContextType = {
  currentUser: JSON.parse(localStorage.getItem("user") || "{}") || null,
  dispatch: () => {},
};

export const AuthContext = createContext(INITIAL_STATE);

export default function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);
  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}
