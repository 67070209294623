import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { CreateLocationOptions, RemovedFile } from "../types";

interface OptionsInterface {
  selectedOptions: CreateLocationOptions;
  setSelectedOptions: Dispatch<SetStateAction<CreateLocationOptions>>;
  removedFiles: RemovedFile[];
  setRemovedFiles: Dispatch<SetStateAction<RemovedFile[]>>;
  isSubmitted: boolean;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
}

type Props = {
  children: ReactNode;
};
const OptionsContext = createContext<OptionsInterface | null>(null);

export const useOptionsContext = () => {
  const context = useContext(OptionsContext);
  if (!context) {
    throw new Error("useOptionsContext must be used within an OptionsProvider");
  }
  return context;
};
export const OptionsProvider = ({ children }: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<CreateLocationOptions>(
    {
      mainTag: "",
      additionalTag: "",
      files: {
        main_menu: null,
        banners: [],
        rewards: [],
      },
    },
  );

  const [removedFiles, setRemovedFiles] = useState<RemovedFile[]>([]);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const value = {
    selectedOptions,
    setSelectedOptions,
    removedFiles,
    setRemovedFiles,
    isSubmitted,
    setIsSubmitted,
  };

  return (
    <OptionsContext.Provider value={value}>{children}</OptionsContext.Provider>
  );
};
