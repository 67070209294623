import {
  CreateLocationOptions,
  Location,
  MultiLocation,
  RemovedFile,
  Reward,
} from "../../../../types";
import { useQueryClient } from "@tanstack/react-query";
import {
  collection,
  deleteDoc,
  doc,
  GeoPoint,
  getFirestore,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../../firebase/firebaseConfig";
import { geocode, RequestType } from "react-geocode";
import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import { getDocRef, getFileRef } from "../../../../firebase/actions";

const db = getFirestore();
export const createLocation = async (
  location: Location,
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  try {
    const locationsColRef = collection(db, "locations");
    const docRef = doc(locationsColRef);
    await setDoc(docRef, {
      ...location,
      docRef: docRef,
      dateAdded: Timestamp.now(),
      scannedQRs: 0,
    });
    if (process.env.NODE_ENV === "development")
      console.log("location created with id: ", docRef.id);
    await queryClient.invalidateQueries({ queryKey: ["locations"] });
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error creating location: ", error);
    throw error;
  }
};

export const onCreate = async (
  data: Location,
  selectedOptions: CreateLocationOptions,
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  const { mainTag, additionalTag, files } = selectedOptions;
  const tags: string[] = [mainTag, additionalTag].filter(Boolean);
  const { main_menu, banners, rewards } = files;
  const locationName = data.name.replace(/[^a-zA-Z0-9]/g, "_");

  if (main_menu && banners.length > 0 && rewards.length > 0) {
    const mainMenuRef = ref(
      storage,
      `location_pictures/${locationName}/${main_menu.name}`,
    );
    await uploadBytes(mainMenuRef, main_menu);
    data.picture = await getDownloadURL(mainMenuRef);

    data.multiLocations = await Promise.all(
      banners.map(async (banner, index) => {
        const bannerUrls: string[] = [];

        await Promise.all(
          banner.images.map(async (file) => {
            const bannerRef = ref(
              storage,
              `location_pictures/${locationName}/banners/${file.name}`,
            );
            await uploadBytes(bannerRef, file);

            const locationUrl = await getDownloadURL(bannerRef);
            bannerUrls.push(locationUrl);
          }),
        );

        const locationAtIndex = data.multiLocations[index];
        let geoPointsAtIndex: GeoPoint;

        if (typeof locationAtIndex.geoLocation === "string") {
          geoPointsAtIndex = await geocode(
            RequestType.ADDRESS,
            locationAtIndex.geoLocation,
          )
            .then(({ results }) => {
              const { lat, lng } = results[0].geometry.location;
              return new GeoPoint(lat, lng);
            })
            .catch((error) => {
              console.error(error);
              return new GeoPoint(0, 0);
            });
        } else {
          geoPointsAtIndex = locationAtIndex.geoLocation;
        }

        return {
          city: locationAtIndex.city,
          geoLocation: geoPointsAtIndex,
          locationName: locationAtIndex.locationName,
          phone: locationAtIndex.phone,
          pictureHash: locationAtIndex.pictureHash,
          pictures: bannerUrls,
        };
      }),
    );

    data.cities = Array.from(
      new Set(
        await Promise.all(
          data.multiLocations.map(async (location) => {
            return location.city;
          }),
        ),
      ),
    );

    data.rewards = await Promise.all(
      rewards.map(async (file, index) => {
        const rewardRef = ref(
          storage,
          `location_pictures/${locationName}/rewards/${file.name}`,
        );
        await uploadBytes(rewardRef, file);
        const rewardUrl = await getDownloadURL(rewardRef);
        const rewardAtIndex = data.rewards[index];
        return {
          rewardCost: rewardAtIndex.rewardCost,
          rewardDescription: rewardAtIndex.rewardDescription,
          rewardPicture: rewardUrl,
          rewardTitle: rewardAtIndex.rewardTitle,
        };
      }),
    );
  }

  data.tags = tags;

  await createLocation(data, queryClient);
};

// UPDATE LOCATION

export const getAddress = async (
  index: number,
  selectedLocation: Location | null,
) => {
  if (selectedLocation) {
    const geoLocation = selectedLocation.multiLocations[index].geoLocation;
    if (geoLocation instanceof GeoPoint) {
      const { latitude, longitude } = geoLocation;
      try {
        const { results } = await geocode(
          RequestType.LATLNG,
          `${latitude},${longitude}`,
        );
        return results[0].formatted_address;
      } catch (error) {
        console.error("Failed to get address:", error);
        return "";
      }
    } else {
      console.error("geoLocation is not a valid GeoPoint");
      return "";
    }
  }
  return "";
};

const deleteFilesFromStorage = async (
  removedFiles: RemovedFile[],
  selectedLocation: Location | null,
  setErrorMessage: Dispatch<SetStateAction<string | null>>,
) => {
  if (!selectedLocation) {
    setErrorMessage("Location data is not available.");
    return;
  }
  if (removedFiles.length === 0) return;
  try {
    for (const removedFile of removedFiles) {
      const { deleteFrom, from } = removedFile.removedFile;
      const fileRef = getFileRef(selectedLocation, deleteFrom.fullPath, from);
      await deleteObject(fileRef);
    }
  } catch (error) {
    console.error(error);
  }
};

export const onUpdate = async (
  newData: Location,
  selectedLocation: Location | null,
  selectedOptions: CreateLocationOptions,
  removedFiles: RemovedFile[],
  setRemovedFiles: Dispatch<SetStateAction<RemovedFile[]>>,
  setSuccessMessage: Dispatch<SetStateAction<string | null>>,
  setErrorMessage: Dispatch<SetStateAction<string | null>>,
) => {
  if (!selectedLocation) {
    setErrorMessage("Error searching for location data");
    return;
  }
  const { files } = selectedOptions;
  const { main_menu, banners, rewards } = files;
  const locationName = newData.name.replace(/[^a-zA-Z0-9]/g, "_");
  for (const [key, value] of Object.entries(newData)) {
    const objKey = key as keyof Location;
    if (objKey === "picture") {
      const removedFile = removedFiles.find(
        (removedFile) => removedFile.removedFile.from === "main_menu",
      );
      if (removedFile && main_menu === null) {
        setErrorMessage("Upload at least 1 banner image!");
        return;
      } else if (main_menu !== null) {
        if (!removedFile) {
          setErrorMessage("You can only have 1 main image!");
          return;
        }
        const mainMenuRef = ref(
          storage,
          `location_pictures/${locationName}/${main_menu.name}`,
        );
        await uploadBytes(mainMenuRef, main_menu);
        newData.picture = await getDownloadURL(mainMenuRef);
        if (removedFile) {
          const { deleteFrom, from } = removedFile.removedFile;
          const fileRef = getFileRef(
            selectedLocation,
            deleteFrom.fullPath,
            from,
          );
          await deleteObject(fileRef);
        } else {
          const fileRef = getFileRef(
            selectedLocation,
            selectedLocation.picture,
            "main_menu",
          );
          await deleteObject(fileRef);
        }
      }
    } else if (objKey === "multiLocations") {
      const auxMultiLocations = await Promise.all(
        (value as MultiLocation[]).map(async (loc, index) => {
          if (typeof loc.geoLocation === "string") {
            const geoPoint = await geocode(RequestType.ADDRESS, loc.geoLocation)
              .then(({ results }) => {
                const { lat, lng } = results[0].geometry.location;
                return new GeoPoint(lat, lng);
              })
              .catch(() => {
                return new GeoPoint(0, 0);
              });

            loc = { ...loc, geoLocation: geoPoint };
          }

          const currentFilesArray =
            selectedLocation.multiLocations[index].pictures;

          const existingPictures = currentFilesArray.filter((picture) => {
            return !removedFiles.some(
              (removedFile) =>
                removedFile.removedFile.from === "banners" &&
                removedFile.removedFile.index === index &&
                picture.includes(removedFile.removedFile.name),
            );
          });

          const uploadedPictures = await Promise.all(
            banners.map(async (files) => {
              return await Promise.all(
                files.images.map(async (file) => {
                  const fileRef = ref(
                    storage,
                    `location_pictures/${loc.locationName}/banners/${file.name}`,
                  );
                  await uploadBytes(fileRef, file);
                  return await getDownloadURL(fileRef);
                }),
              );
            }),
          ).then((result) => result.flat());

          if (existingPictures.length === 0 && uploadedPictures.length === 0) {
            setErrorMessage(
              `Location ${index + 1} doesn't have any banner pictures!`,
            );
            return { ...loc, pictures: [] };
          }

          const updatedPictures = [...existingPictures, ...uploadedPictures];

          return { ...loc, pictures: updatedPictures };
        }),
      );

      if (auxMultiLocations.some((loc) => loc.pictures.length === 0)) return;

      if (_.isEqual(auxMultiLocations, selectedLocation[objKey]))
        delete (newData as Partial<Location>)[objKey];
      else newData[objKey] = auxMultiLocations;
    } else if (objKey === "rewards") {
      const updatedRewards = await Promise.all(
        (value as Reward[]).map(async (reward, index) => {
          const removedFile = removedFiles.find(
            (file) =>
              file.removedFile.from === "reward" &&
              file.removedFile.index === index,
          );

          let newPicture = reward.rewardPicture;

          const newFile: File | undefined = rewards[index];

          if (newFile) {
            if (removedFile) {
              const { deleteFrom, from } = removedFile.removedFile;
              const fileRef = getFileRef(
                selectedLocation,
                deleteFrom.fullPath,
                from,
              );
              await deleteObject(fileRef);

              const rewardRef = ref(
                storage,
                `location_pictures/${locationName}/rewards/${newFile.name}`,
              );
              await uploadBytes(rewardRef, newFile);
              newPicture = await getDownloadURL(rewardRef);
            } else {
              setErrorMessage("Rewards can only have 1 image!");
              return { ...reward, rewardPicture: "" };
            }
          } else if (!newFile && removedFile) {
            setErrorMessage(`Reward ${index + 1} must have a picture!`);
            return { ...reward, rewardPicture: "" };
          }

          return { ...reward, rewardPicture: newPicture };
        }),
      );

      if (updatedRewards.some((reward) => reward.rewardPicture === "")) return;

      if (!_.isEqual(updatedRewards, selectedLocation[objKey]))
        newData[objKey] = updatedRewards;
      else delete (newData as Partial<Location>)[objKey];
    } else if (objKey === "tags") {
      const updatedTags = [
        selectedOptions.mainTag,
        selectedOptions.additionalTag,
      ];
      if (!_.isEqual(updatedTags, selectedLocation.tags))
        newData[objKey] = updatedTags;
      else delete (newData as Partial<Location>)[objKey];
    } else if (value === selectedLocation[objKey]) delete newData[objKey];
  }

  if (Object.keys(newData).length > 0) {
    try {
      const locationRef = getDocRef(selectedLocation.docID);
      await updateDoc(locationRef, newData);
      await deleteFilesFromStorage(
        removedFiles,
        selectedLocation,
        setErrorMessage,
      );
      setRemovedFiles([]);
      setSuccessMessage("Location updated successfully!");
    } catch (error) {
      setErrorMessage("Failed to update location data.");
      throw error;
    }
  } else setErrorMessage("No changes to update");
};

// DELETE LOCATION

async function deleteFolderRecursively(folderRef: StorageReference) {
  const listResults = await listAll(folderRef);

  const deleteFilesPromises = listResults.items.map((itemRef) =>
    deleteObject(itemRef),
  );

  const deleteSubfoldersPromises = listResults.prefixes.map((subFolderRef) =>
    deleteFolderRecursively(subFolderRef),
  );

  await Promise.all([...deleteFilesPromises, ...deleteSubfoldersPromises]);
}

export async function deleteLocation(
  selectedLocation: Location | null,
  setSuccessMessage: Dispatch<SetStateAction<string | null>>,
  setErrorMessage: Dispatch<SetStateAction<string | null>>,
  queryClient: ReturnType<typeof useQueryClient>,
) {
  if (!selectedLocation) {
    setErrorMessage("Invalid location data");
    return;
  }

  const { docID, name } = selectedLocation;

  try {
    const storage = getStorage();
    const folderRef = ref(storage, `location_pictures/${name}`);

    await deleteFolderRecursively(folderRef);
    const docRef = doc(db, "locations", docID);
    await deleteDoc(docRef);
    await queryClient.invalidateQueries({ queryKey: ["locations"] });

    setSuccessMessage(`Successfully deleted location: ${name}`);
  } catch {
    setErrorMessage(`Error deleting location: ${name}`);
  }
}
