import { UseFormReturn } from "react-hook-form";
import { Location } from "../../../../../types";
import CustomInput from "./CustomInput";
import OptionsSelector from "../OptionsSelector";
import { additional_tags, main_tags } from "../../../../../data";
import FileInput from "./FileInput";
import RewardInputs from "./RewardInputs";
import MultipleLocationInputs from "./MultipleLocationInputs";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";

interface Props {
  formMethods: UseFormReturn<Location>;
}

export default function SharedInputs({ formMethods }: Props) {
  const { register, formState, setError } = formMethods;
  const context = useActionsContext();
  const { mode } = context.state;
  const { errors } = formState;
  return (
    <>
      <CustomInput
        register={register}
        fieldName="name"
        type="text"
        placeholder="People know you by this name"
        options={{
          required: mode === "CREATE" ? "This field is required!" : false,
        }}
        className="mt-1"
        error={errors.name?.message}
        label="Location name"
      />
      <CustomInput
        register={register}
        fieldName="description"
        type="text"
        placeholder="The place where you use fancy words"
        options={{
          required: mode === "CREATE" ? "This field is required!" : false,
        }}
        className="mt-1"
        error={errors.description?.message}
        label="Description"
      />
      <CustomInput
        register={register}
        fieldName="pointsRate"
        type="number"
        placeholder='e.g: Type "10" for 10% return'
        options={{
          required: mode === "CREATE" ? "This field is required!" : false,
          valueAsNumber: true,
        }}
        inputMode="numeric"
        onKeyDown={(e) => {
          if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
            e.preventDefault();
          }
        }}
        className="mt-1"
        error={errors.pointsRate?.message}
        label="Conversion Rate"
      />
      <MultipleLocationInputs formMethods={formMethods} />
      <FileInput
        register={register}
        fieldName="picture"
        setError={setError}
        options={{
          required: mode === "CREATE" ? "This field is required!" : false,
        }}
        label={`${
          mode === "CREATE" ? "Upload the main image" : "Change the main image"
        }`}
        For="main_menu"
        formState={formState}
      />

      <RewardInputs formMethods={formMethods} />
      <OptionsSelector
        options={main_tags}
        label="Select a cusine tag:"
        fieldName="mainTag"
        selectionMode="single"
        wrapperClass="mt-4"
        gridClass="grid grid-cols-2 gap-2 mt-2"
        buttonClass="bg-admin-dark-3"
      />
      <OptionsSelector
        options={additional_tags}
        label="Select an additional tag:"
        fieldName="additionalTag"
        selectionMode="single"
        wrapperClass="mt-4"
        gridClass="grid grid-cols-2 gap-2 mt-2"
        buttonClass="bg-admin-dark-3"
      />
    </>
  );
}
